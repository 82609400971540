import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import AppRouter from './routers/appRouter';
import ParticlesBg from 'particles-bg';
import ReactGA from 'react-ga4'; // Import react-ga4 for GA4

const App = () => {

    useEffect(() => {
        ReactGA.initialize('G-N2DY5QGC50'); // Replace with your GA4 tracking ID
        ReactGA.send('pageview'); // Track the initial pageview when the app loads
    }, []);

    // Inline styles for the background image
    const transparentImageStyle = {
        position: 'absolute',  // Position the image absolutely
        top: 0,
        left: 0,
        width: '100%',        // Make the image cover the full width
        height: '85vh',       // Set the height to a smaller value (30% of the viewport height)
        opacity: 0.5,         // Adjust transparency (0.0 = fully transparent, 1.0 = fully opaque)
        zIndex: -1,           // Place the image behind other content
        objectFit: 'cover',   // Ensures the image covers the area without distortion
    };

    // Inline styles for the main App container
    const appStyle = {
        position: 'relative',   // Ensure the App component is positioned correctly
        zIndex: 1,              // Make sure App content is on top of the background
        height: '100vh',        // Ensure the height of the App is 100% of the viewport
        overflowY: 'auto',      // Enable vertical scrolling
        overflowX: 'hidden',    // Prevent horizontal scrolling
        cursor: 'url("custom-cursor.png"), auto', // Custom cursor style
    };

    return (
        <Router> {/* Wrap the App inside Router */}
            <div style={appStyle} className='App'>
                {/* Background Image */}
                {/* <img src="background.jpg" alt="Background" style={transparentImageStyle} /> */}

                {/* Particles Background */}
                <ParticlesBg
                    type="cobweb"
                    bg={{
                        position: 'absolute',
                        zIndex: -1,
                        opacity: 0.5,
                    }}
                />

                {/* Main Application Router */}
                <AppRouter />
            </div>
        </Router>
    );
};

export default App;
